@import url('https://fonts.googleapis.com/css?family=ABeeZee');
@import url('https://fonts.googleapis.com/css?family=Mountains+of+Christmas');
@import url('https://fonts.googleapis.com/css?family=Clicker+Script');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.herb_img {
  margin-bottom: 10px;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.herb_name {
  font-family: "Clicker Script";
  margin-top: 0px;
  margin-bottom: 10px;
}